import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Pagination, Table } from "../../../components";
import { DataFrame, df2dfd, dfd2df } from "../../../helpers";
import { clientService } from "../../../services";
import { AppState } from "../../../stores";
import { loadClientDetail } from "../../../stores/clients/actions";

export const ClientDetail = () => {
  let { id } = useParams() as any;
  const dispatch = useDispatch();
  const limitExpand = 20;
  const [isExpandExpectedPNL, setIsExpandExpectedPNL] = useState(false);
  const [isExpandRealisedPNL, setIsExpandRealisedPNL] = useState(false);
  const [isExpandDeposite, setIsExpandDeposite] = useState(false);
  // const [isExpandPortfolio, setIsExpandPortfolio] = useState(false)
  // const [isExpandAssets, setIsExpandAssets] = useState(false)
  const expectedPNL = useSelector(
    (state: AppState) => state.clientDetail.detail?.data?.expectedPNL
  );
  const deposite = useSelector(
    (state: AppState) => state.clientDetail.detail?.data?.deposite
  );
  const portfolio = useSelector(
    (state: AppState) => state.clientDetail.detail?.data?.portfolio
  );
  const assets = useSelector(
    (state: AppState) => state.clientDetail.detail?.data?.assets
  );
  const [realisedPNL, setRealisedPNL] = useState<DataFrame>({
    schema: [],
    records: [],
  });
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 20;
  const onPageChanged = (page: number) => {
    setCurrentPage(page);
  };
  const loadRealisedPNL = async () => {
    const data = await clientService
      .loadRealisedPNL(id, currentPage - 1, pageSize)
      .then((res) => {
        return res.data;
      });
    const map = {
      dateBuy: "Ngày Mua",
      dateSell: "Ngày Bán",
      ticker: "Mã hợp đồng",
      quantity: "Khối lượng",
      priceBuy: "Giá Mua",
      priceSell: "Giá Bán",
      dealType: "Loại deal",
      taxCost: "Phí: Thuế",
      orderCost: "Phí: Mua/bán",
      orderCostVsd: "Phí: Mua/bán trả Vsd",
      overNightCost: "Phí: Qua đêm",
      totalCost: "Tổng giá trị Phí",
      realisedPnl: "[Thực tế đã trừ phí] Lãi lỗ",
    } as any;
    var dfd = df2dfd(data.data);
    dfd = dfd.reorderSeries(Object.keys(map));
    const roundCols = {} as any;
    Object.keys(map).filter((key: any) => {
      if (
        !["dateBuy", "dateSell", "ticker", "priceBuy", "priceSell"].includes(
          key
        )
      ) {
        roundCols[key] = (row: any) =>
          Number(row[key]) === row[key] ? Math.round(row[key]) : row[key];
      }
    });
    dfd = dfd.generateSeries(roundCols);
    dfd = dfd.renameSeries(map);
    setRealisedPNL(dfd2df(dfd));
    setTotal(data.total);
  };
  const filterData = (
    data: any,
    isExpand: boolean,
    includeLastRow: boolean
  ) => {
    if (data) {
      const newData = Object.assign({}, data);
      newData.records = isExpand
        ? data.records
        : data.records.slice(
            0,
            Math.min(limitExpand - 1, data.records.length - 1)
          );
      if (includeLastRow && !isExpand) {
        newData.records.push(data.records.at(-1));
      }
      return newData;
    }
    return data;
  };
  useEffect(() => {
    if (id) loadClientDetail(id)(dispatch);
    loadRealisedPNL();
    const intervalId = setInterval(
      () => {
        if (id) loadClientDetail(id)(dispatch);
        loadRealisedPNL();
      },
      id ? 1000 * 5 : 0
    );
    return () => clearInterval(intervalId);
  }, [dispatch, id, currentPage]);
  return (
    <div className="container-fluid">
      {/* Page Heading */}
      <h1 className="h3 mb-4 text-dark">{`Báo cáo khách hàng ${id}`}</h1>
      <div className="row">
        <div className="col-lg">
          <h1 className="h4 mb-2 text-dark">BÁO CÁO TÀI SẢN</h1>
          <div className="card shadow mb-4">
            <div className="card-body">
              {
                <Table
                  data={assets}
                  hightLightLastRow={false}
                  sortElements={[]}
                ></Table>
              }
            </div>
            <div className="card-footer"></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <h1 className="h4 mb-2 text-dark">THEO DÕI NỘP CỌC</h1>
          <div className="card shadow mb-4">
            <div className="card-body">
              <Table
                data={filterData(deposite, isExpandDeposite, true)}
                hightLightLastRow={true}
                sortElements={[]}
              ></Table>
            </div>
            {deposite?.records && deposite.records.length > limitExpand ? (
              <div
                role="button"
                onClick={() => {
                  setIsExpandDeposite(!isExpandDeposite);
                }}
              >
                <u>{isExpandDeposite ? "thu gọn" : "xem toàn bộ"}</u>
              </div>
            ) : (
              <div />
            )}
            <div className="card-footer"></div>
          </div>
        </div>
        <div className="col-lg-8">
          <h1 className="h4 mb-2 text-dark">DANH MỤC CỔ PHIẾU HIỆN TẠI</h1>
          <div className="card shadow mb-4">
            <div className="card-body">
              <Table
                data={portfolio}
                hightLightLastRow={true}
                sortElements={[]}
              ></Table>
            </div>
            <div className="card-footer"></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg">
          <h1 className="h4 mb-2 text-dark">DANH MỤC LỢI NHUẬN KỲ VỌNG</h1>
          <div className="card shadow mb-4">
            <div className="card-body">
              <Table
                data={filterData(expectedPNL, isExpandExpectedPNL, true)}
                hightLightLastRow={true}
                sortElements={[]}
              ></Table>
            </div>
            {expectedPNL?.records.length &&
            expectedPNL.records.length > limitExpand ? (
              <div
                role="button"
                onClick={() => {
                  setIsExpandExpectedPNL(!isExpandExpectedPNL);
                }}
              >
                <u>{isExpandExpectedPNL ? "thu gọn" : "xem toàn bộ"}</u>
              </div>
            ) : (
              <div />
            )}
            <div className="card-footer"></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg">
          <h1 className="h4 mb-2 text-dark">DANH MỤC LỢI NHUẬN ĐÃ TẤT TOÁN</h1>
          <div className="card shadow mb-4">
            <div className="card-body">
              <Table
                data={realisedPNL}
                hightLightLastRow={true}
                sortElements={[]}
              ></Table>
            </div>
            <div className="card-footer">
              <div className="card-footer">
                <Pagination
                  total={total}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  pageLimit={5}
                  onPageChanged={onPageChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
