import $ from "jquery";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Table } from "../../../components";
import { df2dfd, dfd2df } from "../../../helpers";
import { clientService } from "../../../services";

export const Portfolio = () => {
  const filterColumns = ["idClient", "ticker"];
  let { brokerName } = useParams();
  const [portfolioRes, setPortfolioRes] = useState<Record<any, any>>({});
  const [filterBy, setFilterBy] = useState<any>({});
  const [filterByDelay, setFilterByDelay] = useState<any>({});
  const getFilters = () => {
    const filterBy = {} as any;
    for (const col of filterColumns) {
      const value = $(`#${col}Input`).val() as string;
      filterBy[col] = value === "" ? undefined : value;
    }
    return filterBy;
  };
  const onChangeFilter = (e: any) => {
    setFilterBy(getFilters());
  };

  const setNewInterval = (brokerName: any, filterBy: any) => {
    if (brokerName) loadPortfolio(brokerName, filterBy);
    const intervalId = setInterval(
      () => {
        if (brokerName) loadPortfolio(brokerName, filterBy);
      },
      brokerName ? 1000 * 5 : 0
    );
    return () => {
      clearInterval(intervalId);
    };
  };

  const loadPortfolio = async (brokerName: any, filterBy: any) => {
    console.log(filterBy);
    await clientService.loadPortfolio(brokerName, filterBy).then(
      (res) => {
        var dfd = df2dfd(res.data.data);
        // dfd = dfd.generateSeries({
        //     updatedAt: (row) => <span className="text-nowrap">{row.updatedAt}</span>,
        // })
        const map = {
          idClient: "Tài khoản",
          nameClient: "Tên",
          ticker: "Mã hợp đồng",
          quantity: "Khối lượng",
          priceBuy: "Giá mua",
          priceSell: "Giá bán",
          dealType: "Loại deal",
          totalCost: "Tổng giá trị Phí",
          realisedPnl: "[Thực tế đã trừ phí] Lãi lỗ  ",
          refPrice: "Giá tham chiếu",
          pnlVmExFee: "Lãi lỗ VM (chưa tính phí)",
          totalValueCountsForDeposit: "Tồng tiền tính cọc",
          totalValueBuy: "Tổng tiền mua",
          totalValueSell: "Tổng tiền bán",
          __updatedAt__: "Ngày/Giờ cập nhật",
        } as any;
        const roundCols = {} as any;
        Object.keys(map).filter((key: any) => {
          if (
            ![
              "idClient",
              "nameClient",
              "ticker",
              "updatedAt",
              "refPrice",
              "priceBuy",
              "priceSell",
              "dealType",
              "__updatedAt__",
            ].includes(key)
          ) {
            roundCols[key] = (row: any) => Math.round(row[key]);
          }
          if (key === "idClient") {
            roundCols[key] = (row: any) => (
              <Link className="text-dark" to={`/clients/${row.idClient}`}>
                {row.idClient}
              </Link>
            );
          }
        });
        dfd = dfd.generateSeries(roundCols);
        dfd = dfd.reorderSeries(Object.keys(map));
        const filterRows = [];
        for (const col of dfd.getColumnNames()) {
          if (filterColumns.includes(col)) {
            filterRows.push(
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="search"
                onChange={onChangeFilter}
                id={`${col}Input`}
              />
            );
          } else {
            filterRows.push(undefined);
          }
        }
        dfd = dfd.renameSeries(map);
        res.data.data = dfd2df(dfd);
        res.data.data.records = [filterRows].concat(res.data.data.records);
        setPortfolioRes(res);
      },
      (error) => {
        setPortfolioRes(error.res);
      }
    );
  };
  useEffect(() => {
    return setNewInterval(brokerName, filterBy);
  }, [brokerName, filterByDelay]);
  useEffect(() => {
    const timeOutId = setTimeout(() => setFilterByDelay(filterBy), 1000);
    return () => clearTimeout(timeOutId);
  }, [filterBy]);

  return (
    <div className="container-fluid">
      {/* Page Heading */}
      <h1 className="h3 mb-4 text-dark">{`Báo cáo Danh mục: ${brokerName}`}</h1>
      <div className="row">
        <div className="col-lg">
          <h1 className="h4 mb-2 text-dark"></h1>
          <div className="card shadow mb-4">
            <div className="card-body">
              {portfolioRes.data?.data ? (
                <Table
                  data={portfolioRes.data.data}
                  hightLightLastRow={false}
                  sortElements={[]}
                  freezeSecondColumn
                ></Table>
              ) : (
                <></>
              )}
            </div>
            <div className="card-footer"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
